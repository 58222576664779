<template>
  <main class="navbar-offset-tablet">
    <PageNavigator
      :page-title="$t('salon-services.breadcrumbs-list-label')"
      :has-back="true"
      :button-back-link="'/salon'"
    >
      <template v-slot:action>
        <div>
          <b-link :to="'/salon/services/create'" class="btn btn-primary px-4 py-1">
            <span class="font-weight-medium poppins-font">{{ $t('common.add_service') }}</span>
          </b-link>
        </div>
      </template>
    </PageNavigator>

    <div class="px-1-5 pt-7-5 full-page-height salon-flow">
      <div class="d-flex flex-wrap h-100 justify-content-center">
        <ServiceTable
          v-if="!isLoading"
          :items="services.data"
          @change-sort="changeSort"
          @delete-item="deleteService"
        ></ServiceTable>
        <CustomPagination
          :data="services"
          :show-disabled="true"
          @pagination-change-page="changePaginationPage"
        ></CustomPagination>
      </div>
    </div>
  </main>
</template>

<script>
import PageNavigator from '@/components/PageNavigator'
import ServiceTable from '@/components/salon/ServiceTable'
import CustomPagination from '@/components/CustomPagination'
import Spinner from '../../Spinner'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SalonServicesList',
  components: {
    PageNavigator,
    ServiceTable,
    CustomPagination,
    Spinner
  },
  data() {
    return {
      isLoading: false,
      payloadData: {
        salon_id: null,
        page: 1,
        sort: '-id'
      },
      deletedSuccessMessage: {
        type: this.$t('salon-services.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.deleted'
      },
      deleteLastInfoMessage: {
        type: 'info',
        messageI18Key: 'common.alerts.actions.delete_last_info'
      },
      deleteErrorMessage: {
        type: 'error',
        messageI18Key: 'common.alerts.actions.global_error'
      }
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      services: 'services/getServices'
    })
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions({
      fetchServicesBySalonId: 'services/getServicesBySalonId',
      deleteServiceById: 'services/deleteServiceById',
      setError: 'userMessages/setError',
      setSuccess: 'userMessages/setSuccess'
    }),
    setLoadingFlag(val) {
      this.isLoading = val
    },
    changeSort(val) {
      this.payloadData.sort = val

      this.fetchServicesBySalonId(this.payloadData)
    },
    changePaginationPage(page = 1) {
      this.payloadData.page = page

      this.fetchServicesBySalonId(this.payloadData)
    },
    setPreviousPageForRequest() {
      if (
        this.services.data.length === 0 &&
        this.services.current_page === this.services.last_page &&
        this.services.current_page !== 1
      ) {
        this.payloadData.page = this.payloadData.page - 1
      }
    },
    confirmMessage() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [this.$t('common.confirm.delete_title')])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          cancelVariant: 'light',
          cancelTitle: `${this.$t('alert_message.cancel_title')}`
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    },
    deleteProcess(serviceData) {
      this.setLoadingFlag(true)

      this.deleteServiceById(serviceData)
        .then(() => {
          if (this.services.data.length === 0 || this.services.total === 10) {
            this.setPreviousPageForRequest()
            this.fetchData()
          }

          this.setLoadingFlag(false)

          this.setSuccess({
            messageI18Key: 'common.alerts.services.deleted',
            successCode: 200,
            successExists: true
          })
        })
        .catch(error => {
          this.setLoadingFlag(false)

          if (error.message === "You can't delete last service") {
            this.setError(this.deleteLastInfoMessage)
          }
        })
    },
    deleteService(serviceData) {
      this.confirmMessage()
        .then(value => {
          if (value) {
            this.deleteProcess(serviceData)
          }
        })
        .catch(error => {})
    },
    fetchData() {
      if (this.accountInfo.salon_id) {
        this.payloadData.salon_id = this.accountInfo.salon_id

        this.setLoadingFlag(true)

        this.fetchServicesBySalonId(this.payloadData)
          .then(response => {
            this.setLoadingFlag(false)
          })
          .catch(() => {
            this.setLoadingFlag(false)
            this.setError({
              errorType: 'service_loading',
              messageI18Key: 'common.alerts.actions.global_error'
            })
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
